import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { User } from '../administration/user.model';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ErrorService} from '../error/error.service';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {Sujeto} from '../subject/sujeto.model';
import {Group} from '../administration/group.model';
import {AdministrationService} from '../administration/administration.service';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class LoginService {

  private url = environment.backend;

  constructor(private http: Http, private errorService: ErrorService, private administrationService: AdministrationService) {
  }

  signin(user: User) {
    const body = JSON.stringify(user);
    const headers = new Headers({'Content-Type': 'application/json'});

    return this.http.post(this.url + 'userauth/signin', body, {headers: headers} )
      .map((response: Response) => response.json())
      .catch((error: Response) => {
         this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  logout() {
    localStorage.clear();
  }

  isLoggedIn() { // Verifica la sesion en el forntend

    if (localStorage.getItem('token') == null) {
      return false;
    } else {
      return true;
    }
  }

  updateToken() { // Verifica la sesión en el backend
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';
    return this.http.get(this.url + 'checklogin/update' + token)
      .map((response: Response) => response.json())
        /*const newToken = response.json().token;
        const userid = response.json().userId;
        console.log(newToken);
        console.log(userid);*/
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }


  isBackLogged() { // Verifica la sesión en el backend
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.get(this.url + 'checklogin' + token)
      .map((response: Response) => {
        const r = response.json().obj;
        if (r === true) {
          return true;
        } else {return false; }
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  setUser() {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();

    let fecha: string;
    fecha = dd + '/' + mm + '/' + yyyy;


    const value = localStorage.getItem('usuario');

    if ( value ) {
      return  fecha  + ' - ' + localStorage.getItem('usuario');
    }

    return fecha;


  }

  loadRole(key: string) {
    const headers = new Headers({'Content-Type': 'application/json'});
    return this.http.get(this.url + 'role?roleid=' + key, {headers: headers} )
      .map((resp: Response) => {
        const obj = resp.json().obj.name;
        return obj;
      });
  }

  /*loadGroup(key: string ) {
    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';
    const headers = new Headers({'Content-Type': 'application/json'});
    return this.http.get(this.url + 'group?groupid=' + key + token, {headers: headers} )
      .map((resp: Response) => {
        const obj = resp.json().obj.name;
        console.log(obj);
        return obj;
      });
  }*/

  loadUserById(key: string) {
    const headers = new Headers({'Content-Type': 'application/json'});
    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';
    return this.http.get(this.url + 'userauth?userid=' + key + token, {headers: headers})
      .map((response: Response) => {
        const user = response.json().obj;
        let transformedUser: User;
        transformedUser = new User(user.email, user.password, user.notify, user.isuser,  user.firstName, user.lastName, [], [], user._id, user.phone);
        const roles = user.roles;
        for (const role of roles) {
          transformedUser.roles.push({name: role});
        }
        const groups = user.groups;
        for (const group of groups){
          transformedUser.groups.push({name: group});
        }

        transformedUser.phone = user.phone
        return transformedUser;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        // console.log(error);
        return Observable.throw(error.json());
      });
  }

  canEnroll() {
    const enroller = localStorage.getItem('rolEnroller');
    //const authorizator = localStorage.getItem('rolAuthorizator');
    if (enroller) {
      return true;
    }
    return false;
  }

  canMarketing() {
    const marketing = localStorage.getItem('rolMarketing');
    if (marketing) {
      return true;
    }
    return false;
  }

  canBioweb() {
    const enroller = localStorage.getItem('rolEnroller');
    const authorizator = localStorage.getItem('rolAuthorizator');
    const viewer = localStorage.getItem('rolViewer');
    if (enroller || authorizator || viewer) {
      return true;
    }
    return false;
  }

  canConfig() {
    const config = localStorage.getItem('rolConfig');
    if (config) {
      return true;
    }
    return false;
  }

  canActivate() {
    const authorizator = localStorage.getItem('rolAuthorizator');
    if (authorizator ) {
      return true;
    }
    return false;
  }
  canAdmin() {
    const administrator = localStorage.getItem('rolAdministrator');
    if (administrator) {
      return true;
    }
    return false;
  }

  grupoPermitido(g: Group) {
    const value = localStorage.getItem('group' + g.name);
    if (value) {
      return true;
    }
    return false;
  }
}
