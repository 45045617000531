import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import {Sujeto} from '../subject/sujeto.model';
import {AdministrationService} from '../administration/administration.service';
import {Group} from '../administration/group.model';
import {SearchService} from './search.service';
import {NgForm} from '@angular/forms';
import {isUndefined} from 'util';
import {SubjectService} from '../subject/subject.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {LoginService} from '../login/login.service';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  subjects: Sujeto[];
  overlay = false;
  groups: Group[];
  estados: string[];
  filterName: string;
  filterLastName: string;
  filterCode: string;
  loading = false;
  title = 'Search Subjects';
  chosenOption: {name: string};
  chosenStatus: string;
  sinConexion: boolean;
  resultados: number;
  error = '';
  perPage: number;

  searchData: {
    name: string,
    lastName: string,
    code: string,
    group: string,
    status: string,
    perPage: number,
    page: number
  }

  todos: any;
  total: any;
  p = 1;

  constructor(private subjectService: SubjectService,
              private router: Router,
              private administrationService: AdministrationService,
              private confirmationDialogService: ConfirmationDialogService,
              private searchService: SearchService,
              private loginService: LoginService,
              private activatedRoute: ActivatedRoute) {
    this.updateToken();

    this.subjects = [];
    this.estados = [];
    this.sinConexion = false;
    this.chosenOption = {name: 'Any Group'};
    this.chosenStatus =  'Any Status';
    this.searchData = {name: '', lastName: '', code: '', group: '', status: '', perPage: 6, page: 1};
    this.searchData.perPage = 6;
    this.perPage = 6;
    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['code'];
      if (code) {
        console.log(code);
        this.filterCode = code;
        this.searchData.code = code;
        this.getPage(1);
      }
    });
  }
  isLoading() {
    return this.loading;
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  ngOnInit() {


    this.estados.push('Active');
    this.estados.push('Pending');
    this.estados.push('Expired');
    this.searchService.getGroups().subscribe(
      (groups: Group[]) => {
        this.groups = groups;
      })
    this.searchService.getCount().subscribe(
      (res: Response) => {
        this.todos = res;
      });
    // this.getPage(1);
  }

  isValid(form: NgForm) {
    return true;
  }
  resetForm(form: NgForm) {
    this.subjects = [];
    this.chosenOption.name = 'Any Group';
    this.chosenStatus = 'Any Status';
    this.filterName = '';
    this.filterLastName = '';
    this.filterCode = '';
  }
  onSubmit(form: NgForm) {
    this.loading = true;
     this.searchData.name = form.value.filterName;
     this.searchData.lastName = form.value.filterLastName;
     this.searchData.code = form.value.filterCode;
     this.searchData.group = form.value.chosenOption.name;
     this.searchData.status = form.value.chosenStatus;
    this.searchData.perPage = this.perPage;

     if (isUndefined(form.value.chosenOption.name)) {
      this.searchData.group = form.value.chosenOption;
     }

    if (isUndefined(this.searchData.name)) {
      this.searchData.name = '';
    }
    if (isUndefined(this.searchData.lastName)) {
      this.searchData.lastName = '';
    }
    if (isUndefined(this.searchData.code)) {
      this.searchData.code = '';
    }

    if (this.searchData.group === 'Any Group') {
      this.searchData.group = '';
    }

    if (this.searchData.status === 'Any Status') {
      this.searchData.status = '';
    }
    this.getPage(1);
  }


  onClickedPerPage(event) {
    if (event.target.selectedOptions[0].value){
      this.perPage = event.target.selectedOptions[0].value;
    }
  }


  getPage(page: number) {
    this.p = page;
    this.searchData.perPage = +this.perPage;
    this.searchData.page = page;
    this.loading = true;


    this.searchService.search(this.searchData)
      .subscribe(
        (list: Sujeto[]) => {
          const transformedsubjects: Sujeto[] = [];
          if (list.length > 0) {
            this.total = list[0].Active;
            this.resultados = list.length + 1;
            this.loading = true;
            let place = 0;
            // this.subjects = list;
            for (const subject of list) {
              if (subject.UserCode !== '0') {
                place = place + 1;
                this.subjectService.getSubject(subject.UserCode)
                  .subscribe(
                    (s: Sujeto) => {
                      transformedsubjects.push(s);
                    },
                    (err) => {
                    },
                    () => {
                          // console.log(transformedsubjects.length);
                          // console.log(list.length);
                          if (transformedsubjects.length === list.length  ) {
                            this.loading = false;
                          }
                    }
                  );
              }
            }
            this.subjects = transformedsubjects;
          } else {
            this.subjects = [];
            this.total = 0;
            this.loading = false;
          }
        },
        (err) => {console.error(err); this.sinConexion = true; },
        () => {

        }
      );
  }

  onDelete(s: Sujeto) {
    this.confirmationDialogService.confirm('Delete', 'Delete subject ' + s.UserCode + '?')
      .then((confirmed) => {
        this.overlay = true;
        if (confirmed) {
          this.subjectService.deleteSubject(s)
            .subscribe(
              result => {
                if (result.obj.DeleteUserResult === true) {
                  // this.subjects.splice(this.subjects.indexOf(s), 1);
                  this.getPage(1);
                  this.overlay = false;
                }

              },
              (err) => {
                this.overlay = false;
                console.error(err); }
            );
        }else{
          this.overlay = false;
        }
        /*t*/
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



    /*
*/


  }

  onActivate(s: Sujeto) {
    this.confirmationDialogService.confirm('Activate', 'Activate subject ' + s.UserCode + '?')
      .then((confirmed) => {
        this.overlay = true;
        if (confirmed) {
          s.Active = 'true';
          s.Comments = 'Bioweb - Activated by ' + localStorage.getItem('usuario');
          this.subjectService.saveSubjectDetails(s)
            .subscribe(
              result => {
                // console.log(result);

              },
              (err) => {
                this.overlay = false;
                console.error(err); }
              ,
              // The 3rd callback handles the "complete" event.
              () => {
                this.overlay = false;
                this.router.navigateByUrl('/search?code=' + s.UserCode);
              }
            );

        }else{
          this.overlay = false;
        }
        /*t*/
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  onDeActivate(s: Sujeto) {
    this.confirmationDialogService.confirm('Deactivate', 'Deactivate subject ' + s.UserCode + '?')
      .then((confirmed) => {
        this.overlay = true;
        if (confirmed) {
          s.Active = 'false';
          s.Comments = 'Bioweb - Deactivated by ' + localStorage.getItem('usuario');
          this.subjectService.saveSubjectDetails(s)
            .subscribe(
              result => {
                // console.log(result);

              },
              (err) => {
                this.overlay = false;
                console.error(err); }
              ,
              // The 3rd callback handles the "complete" event.
              () => {
                this.overlay = false;
                this.router.navigateByUrl('/search?code=' + s.UserCode);
              }
            );

        }else{
          this.overlay = false;
        }
        /*t*/
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }


  esActivo(active: string) {
    if (active === 'true') {
      return true;
    }
    return false;
  }
  mostrarAutorizar(subject: Sujeto) {
    let g: Group;
    g = new Group('');
    g.name = subject.Group;
    if (!this.esActivo(subject.Active) && this.loginService.canActivate() && this.loginService.grupoPermitido(g)) {
      const expiry = new Date(subject.ExpiryDate);
      const today = new Date();
      if (expiry > today) {
        return true;
      }
    }
    return false;
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  mostrarDesactivar(subject: Sujeto) {
    let g: Group;
    g = new Group('');
    g.name = subject.Group;
    if (this.esActivo(subject.Active) && this.loginService.canActivate() && this.loginService.grupoPermitido(g)) {
      const expiry = new Date(subject.ExpiryDate);
      const today = new Date();
      if (expiry > today) {
        return true;
      }
    }
    return false;
  }
  canDelete(grupo: string, subject: Sujeto) {
    let g: Group;
    g = new Group('');
    g.name = subject.Group;
    if (this.loginService.canActivate()) {
      if ( this.loginService.grupoPermitido(g)) {
        return true;
      }
    }
    return false;
  }
}
