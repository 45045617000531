import { Http, Response, Headers } from '@angular/http';
import { Injectable, EventEmitter } from '@angular/core';

import {User} from './user.model';
import { Role } from './role.model';
import { Group } from './group.model';
import { environment } from '../../environments/environment';

import {Observable, Subject} from 'rxjs';
import {ErrorService} from '../error/error.service';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';



@Injectable()
export class AdministrationService {
  private users: User[] = [];
  userIsEdit = new EventEmitter<User>();

  private roles: Role[] = [];
  private groups: Group[] = [];
  private url = environment.backend;

  constructor(private http: Http, private errorService: ErrorService) {
  }
  getRoles() {
    return this.http.get(this.url + 'role')
      .map((response: Response) => {
        const roles = response.json().obj;
        const transformedRoles: Role[] = [];
        for (const role of roles) {
          transformedRoles.push(new Role(
            role.name)
          );
        }
        this.roles = transformedRoles;
        return transformedRoles;
      })
      .catch((error: Response) => {
        // this.errorService.handleError(error.json());
        console.log(error);
        return Observable.throw(error.json());
      });
  }

  getUsers() {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.get(this.url + 'userauth' + token)
      .map((response: Response) => {
        const users = response.json().obj;
        const transformedUsers: User[] = [];
        for (const user of users) {
          //if (user._id !== '5a8ae77731180d0434611c9d'){
            transformedUsers.push(new User(user.email, user.password, user.notify, user.isuser, user.firstName, user.lastName, [], [], user._id, user.phone));
          //}
        }
        this.users = transformedUsers;
        return transformedUsers;
      })
      .catch((error: Response) => {
         this.errorService.handleError(error.json());
        console.log(error);
        return Observable.throw(error.json());
      });
  }

  getGroups() {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.get(this.url + 'group' + token)
      .map((response: Response) => {
        const groups = response.json().obj;
        const transformedGroups: Group[] = [];
        for (const group of groups) {
          transformedGroups.push(new Group(
            group.name)
          );
        }
        this.groups = transformedGroups;
        return transformedGroups;
      })
      .catch((error: Response) => {
         this.errorService.handleError(error.json());
        //console.log(error);
        return Observable.throw(error.json());
      });
  }


  addUser(user: User) {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    const body = JSON.stringify(user);
    const headers = new Headers({'Content-Type': 'application/json'});
    return this.http.post(this.url + 'userauth' + token, body, {headers: headers})
      .map((response: Response) => response.json())
      .catch((error: Response) => {
         this.errorService.handleError(error.json());
        //console.log(error);
        return Observable.throw(error.json());
      });
  }

  editUser(user: User) {

    //console.log(user.roles);

    const body = JSON.stringify(user);
    const headers = new Headers({'Content-Type': 'application/json'});

    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.patch(this.url + 'userauth/' + user.id + token, body, {headers: headers})
      .map((response: Response) => response.json())
      .catch((error: Response) => {
         this.errorService.handleError(error.json());
         //console.log(error);
        return Observable.throw(error.json());
      });
  }

  deleteUser(user: User) {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.delete(this.url + 'userauth/' + user.id + token)
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }


  deleteGroup(group: Group) {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.delete(this.url + 'group/' + group.name + token)
      .map((response: Response) => response.json())
      .catch((error: Response) => {
         this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  addGroup(group: Group) {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    const body = JSON.stringify(group);
    const headers = new Headers({'Content-Type': 'application/json'});
    return this.http.post(this.url + 'group' + token, body, {headers: headers})
      .map((response: Response) => response.json())
      .catch((error: Response) => {
         this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }
}
