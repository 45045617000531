import {Routes} from '@angular/router';

import {UserComponent} from './user/user.component';
import {UserlistComponent} from './userlist/userlist.component';
import {GroupComponent} from './group/group.component';
import {GrouplistComponent} from './grouplist/grouplist.component';

export const ADMINISTRATION_ROUTES: Routes = [
  { path: '', redirectTo: 'userlist', pathMatch: 'full' },
  { path: 'userlist', component: UserlistComponent },
  { path: 'user', component: UserComponent },
  {path: 'user/:codigo', component: UserComponent }
];
