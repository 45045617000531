import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../login/login.service';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import { ReportsService } from '../services/reports/reports.service';
import { IDemographicsStatisticsRequest } from '../services/reports/demographic-statistics-request.model';
import { FilterComponent } from '../filter/filter.component';
import {BlockUI, NgBlockUI} from "ng-block-ui";

//import { NativeDateAdapter } from '@angular/material';
import alasql from '../../../node_modules/alasql/dist/alasql';




interface IHistogramData {
  name: string,
  data: any,
  color: string
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  // GenderChart
  public genderChartOptions: ChartOptions;
  public genderChartLabels: Label[];
  public genderChartData: number[];
  public genderChartType: ChartType;
  public genderChartLegend;
  public genderChartColors;

  // Ethnicity Chart
  public ethnicityChartOptions: ChartOptions;
  public ethnicityChartLabels: Label[];
  public ethnicityChartData: number[];
  public ethnicityChartType: ChartType;
  public ethnicityChartLegend;
  public ethnicityChartColors;

  // Glasses Chart
  public glassesChartOptions: ChartOptions;
  public glassesChartLabels: Label[];
  public glassesChartData: number[];
  public glassesChartType: ChartType;
  public glassesChartLegend;
  public glassesChartColors;

  // Age Chart
  public ageChartOptions: ChartOptions;
  public ageChartLabels: Label[];
  public ageChartType: ChartType;
  public ageChartLegend;
  public ageChartData: ChartDataSets[];


  //Data
  public dtChartLabels: Label[];
  public dtAsianDataValues: number[];
  public dtBlacksDataValues: number[];
  public dtWhitesDataValues: number[];
  public dtGlassesDataValues: number[];
  public dtNoGlassesDataValues: number[];
  public dtFemaleDataValues: number[];
  public dtMaleDataValues: number[];




  // Dynamic Chart
  public dynamicChartOptions: ChartOptions;
  public dynamicChartLabels: Label[];
  public dynamicChartType: ChartType;
  public dynamicChartLegend;
  public dynamicChartData: ChartDataSets[];


  @ViewChild('filterDemographic', null)
  private filterDemographic: FilterComponent;

  @BlockUI()
  blockUI: NgBlockUI;

  noConnection = false;
  chartsLoaded = false;
  histogramData: any;

  //BORRAR después de que los filtros funcionen
  request: IDemographicsStatisticsRequest = {
    AgeFilter: {EndAge:100, StartAge: 5},
    DateFilter: {DaysOfWeek: [{DayOfWeek: "Monday"},{DayOfWeek: "Tuesday"},{DayOfWeek: "Sunday"},{DayOfWeek: "Thursday"},{DayOfWeek: "Wednesday"},{DayOfWeek: "Saturday"},{DayOfWeek: "Friday"}], EndDate: "2019-11-06T17:42:05.174064-03:00", StartDate: "2016-11-06T17:42:05.173329-03:00"},
    EthnicityFilter: {AddAsianPeople: true, AddBlackPeople: true, AddWhitePeople:true},
    ExcludeSources: {string: []},
    GenderFilter: {AddFemales:true, AddMales:true},
    GlassesFilter:{AddPeopleWithGlasses:true, AddPeopleWithoutGlasses:true}
  };

  constructor(private loginService: LoginService,
              private reportsService: ReportsService ) { }

  ngOnInit() {
    this.getDemographicStatistics(this.request);
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canMarketing(){
    return this.loginService.canMarketing();
  }

  getDemographicStatistics(request) {
    this.blockUI.start();
    this.reportsService.demographicStatistics(request).subscribe(
      data => {
        this.histogramData = data.obj;
        this.createGenderChart(data.obj);
        this.createEthnicityChart(data.obj);
        this.createGlassesChart(data.obj);
        this.createAgeChart(data.obj);
        this.fillDynamicChartWithGender()
      },
      error => {
        console.error(error);
        this.blockUI.stop();
      },
      () => {
        this.chartsLoaded = true;
        this.blockUI.stop();

      }
    )
  }

  createGenderChart(histogramData: any) {
    //console.log(histogramData);
    const labels: string[] = ["Female", "Male"];
    let dataValues: number[] = [];
    let totalValues;

    //console.log(Object.keys(histogramData));

    const femaleCount: number = parseInt(histogramData.TotalDemographicsStatistics.AgregatedGender.TotalFemale,10);
    const maleCount: number = parseInt(histogramData.TotalDemographicsStatistics.AgregatedGender.TotalMale,10);

    dataValues.push(femaleCount);
    dataValues.push(maleCount);
    totalValues = femaleCount + maleCount;

    this.genderChartOptions = {
      responsive: true,
      legend: {
        position: 'right'
      },
      title: {
        display: true,
        text: "Gender"
      }
    };
    this.genderChartLabels = labels;
    this.genderChartData = dataValues;
    this.genderChartType = 'pie';
    this.genderChartLegend = true;
    this.genderChartColors = [
      {
        backgroundColor: ['#f9b5c3', '#a2d9e5'],
      },
    ];
  }

  createEthnicityChart(histogramData: any) {
    //console.log(histogramData);
    const labels: string[] = ["Asian", "Black", "White"];

    //console.log(labels);
    let dataValues: number[] = [];
    let totalValues;

    const asianCount: number = parseInt(histogramData.TotalDemographicsStatistics.AgregatedEthnicity.TotalAsian,10);
    const blackCount: number = parseInt(histogramData.TotalDemographicsStatistics.AgregatedEthnicity.TotalBlack,10);
    const whiteCount: number = parseInt(histogramData.TotalDemographicsStatistics.AgregatedEthnicity.TotalWhite,10);

    dataValues.push(asianCount);
    dataValues.push(blackCount);
    dataValues.push(whiteCount);
    totalValues = asianCount + blackCount + whiteCount;

    //console.log(dataValues);

    this.ethnicityChartOptions = {
      responsive: true,
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: "Ethnicity"
      }
    };
    this.ethnicityChartLabels = labels;
    this.ethnicityChartData = dataValues;
    this.ethnicityChartType = 'pie';
    this.ethnicityChartLegend = true;
    this.ethnicityChartColors = [
      {
        backgroundColor: ['#fbf9d5', '#e0652c', '#f89d7e'],
      },
    ];



  }

  createGlassesChart(histogramData: any) {
    //console.log(histogramData);
    const labels: string[] = ["Glasses", "No glasses"];
    let dataValues: number[] = [];
    let totalValues;

    const glassesCount: number = parseInt(histogramData.TotalDemographicsStatistics.AgregatedGlasses.TotalGlasses,10);
    const noGlassesCount: number = parseInt(histogramData.TotalDemographicsStatistics.AgregatedGlasses.TotalNoGlasess,10);

    dataValues.push(glassesCount);
    dataValues.push(noGlassesCount);
    totalValues = glassesCount + noGlassesCount;

    this.glassesChartOptions = {
      responsive: true,
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: "Glasses"
      }
    };
    this.glassesChartLabels = labels;
    this.glassesChartData = dataValues;
    this.glassesChartType = 'pie';
    this.glassesChartLegend = true;
    this.glassesChartColors = [
      {
        backgroundColor: ['#738898', '#d3d3d3'],
      },
    ];
  }

  createDataTables(histogramData: any){
    let AsianDataValues: number[] = this.histogramData.AsiansTimelineHistogram.histFreqPerBin.double;
    let BlacksDataValues: number[] = this.histogramData.BlacksTimelineHistogram.histFreqPerBin.double;
    let WhitesDataValues: number[] = this.histogramData.WhitesTimelineHistogram.histFreqPerBin.double;

    let GlassesDataValues: number[] = this.histogramData.GlassesTimelineHistogram.histFreqPerBin.double;
    let NoGlassesDataValues: number[] = this.histogramData.NoGlassesTimeileHistogram.histFreqPerBin.double;

    let FemaleDataValues: number[] = this.histogramData.FemaleTimelineHistogram.histFreqPerBin.double;
    let MaleDataValues: number[] = this.histogramData.MaleTimelineHistogram.histFreqPerBin.double;



    const ranges = this.histogramData.AsiansTimelineHistogram.histIntervalData
    let labels1: string[] = [];

    for (var i = parseInt(ranges.Start, 10); i < AsianDataValues.length; i++) {
      labels1.push((i*parseInt(ranges.Step)).toString());
    }

    /*console.log(labels1);
    console.log(AsianDataValues);
    console.log(BlacksDataValues);
    console.log(WhitesDataValues);
    console.log(GlassesDataValues);
    console.log(NoGlassesDataValues);*/


    this.dtChartLabels = labels1;
    this.dtAsianDataValues=this.correr2HorasMenos(AsianDataValues);
    this.dtBlacksDataValues=this.correr2HorasMenos(BlacksDataValues);
    this.dtWhitesDataValues=this.correr2HorasMenos(WhitesDataValues);
    this.dtGlassesDataValues=this.correr2HorasMenos(GlassesDataValues);
    this.dtNoGlassesDataValues=this.correr2HorasMenos(NoGlassesDataValues);
    this.dtFemaleDataValues=this.correr2HorasMenos(FemaleDataValues);
    this.dtMaleDataValues= this.correr2HorasMenos(MaleDataValues);

    console.log(MaleDataValues);

  }

  correr2HorasMenos(data: number[]){
    var result: number[];

    result = data;

    result[0] = data[2];
    result[1] = data[3];
    result[2] = data[4];
    result[3] = data[5];
    result[4] = data[6];
    result[5] = data[7];
    result[6] = data[8];
    result[7] = data[9];
    result[8] = data[10];
    result[9] = data[11];
    result[10] = data[12];
    result[11] = data[13];
    result[12] = data[14];
    result[13] = data[15];
    result[14] = data[16];
    result[15] = data[17];
    result[16] = data[18];
    result[17] = data[19];
    result[18] = data[20];
    result[19] = data[21];
    result[20] = data[22];
    result[21] = data[23];
    result[22] = data[0];
    result[23] = data[1];

    
  

    return result;

  }

  createAgeChart(histogramData: any) {
    this.ageChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {xAxes: [{}], yAxes: [{}]},
      title: {
        display: true,
        text: "People per age range"
      }
    };

    let dataValues: number[] = histogramData.AgeHistogram.histFreqPerBin.double;
    let labels: string[] = [];
    const ranges = histogramData.AgeHistogram.histIntervalData;



    for (var i = parseInt(ranges.Start, 10); i < dataValues.length; i++) {
      labels.push((i*parseInt(ranges.Step)).toString());
    }

    this.ageChartLabels= labels;
    //console.log(this.ageChartLabels);
    this.ageChartType = 'bar';
    this.ageChartLegend = false;
    this.ageChartData = [
      {data: dataValues, label: 'Age', backgroundColor: '#fbc003'}
    ];

    this.createDataTables(this.histogramData);

    //console.log(this.ageChartData[0].data);
  }

  createDynamicChart(histogramDataSpecific: IHistogramData[], title: string, histogramInterval: any) {;
    let dataArray = [];
    let labels: string[] = [];

    histogramDataSpecific.forEach(histogram => {
      dataArray.push({
        data: histogram.data.histFreqPerBin.double,
        label: histogram.name,
        backgroundColor: histogram.color
      })
    });

    const end: number = parseInt(histogramInterval.End, 10);
    for (var i = parseInt(histogramInterval.Start, 10); i <= end; i++) {
      labels.push((i*parseInt(histogramInterval.Step)).toString());
    }

    this.dynamicChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {xAxes: [{}], yAxes: [{}]},
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title
      }
    };
    this.dynamicChartLabels = labels;
    this.dynamicChartType = 'bar';
    this.dynamicChartLegend = true;
    this.dynamicChartData = dataArray;
  }

  fillDynamicChartWithGender(){
    this.createDynamicChart([
      { name: "Female", data: this.histogramData.FemaleTimelineHistogram, color: "#f9b5c3" },
      { name: "Male", data: this.histogramData.MaleTimelineHistogram, color: "#a2d9e5"},
    ], "Gender per hour/day", this.histogramData.FemaleTimelineHistogram.histIntervalData)
  }

  fillDynamicChartWithEthnicity(){
    this.createDynamicChart([
      { name: "Asians", data: this.histogramData.AsiansTimelineHistogram, color: "#fbf9d5" },
      { name: "Blacks", data: this.histogramData.BlacksTimelineHistogram, color: "#e0652c"},
      { name: "Whites", data: this.histogramData.WhitesTimelineHistogram, color: "#f89d7e"}
    ], "Ethnicity per hour/day", this.histogramData.AsiansTimelineHistogram.histIntervalData)


  }

  fillDynamicChartWithGlasses(){
    this.createDynamicChart([
      { name: "Glasses", data: this.histogramData.GlassesTimelineHistogram, color: "#738898" },
      { name: "No glasses", data: this.histogramData.NoGlassesTimeileHistogram, color: "#d3d3d3"},
    ], "Glasses per hour/day", this.histogramData.AsiansTimelineHistogram.histIntervalData)
  }

  onClick(){
    let x = document.querySelector("#chartdata");
    if (x){
      x.scrollIntoView();
    }
  }

  copyTableOne(){
    let table = document.querySelector('#table1');
    let range  =  document.createRange();
    range.selectNodeContents(table);
    let select =  window.getSelection();
    select.removeAllRanges();
    select.addRange(range);
    document.execCommand('copy');

  }

  saveCSVTable1(){
    //var nativeElement = document.getElementById('imprimir');
    //var data = document.getElementById('table1'); 
    var data1 = alasql('SELECT * FROM HTML("#table1",{headers:true})');
    alasql('SELECT * INTO CSV("gender_ethnicity_glasses.csv",{headers:true}) FROM ?', [data1]);
    

  }

  saveCSVTable2(){
    //var nativeElement = document.getElementById('imprimir');
    //var data = document.getElementById('table1'); 
    var data1 = alasql('SELECT * FROM HTML("#table2",{headers:true})');
    alasql('SELECT * INTO CSV("age.csv",{headers:true}) FROM ?', [data1]);
    

  }

  

 

  copyTableTwo(){
    let table = document.querySelector('#table2');
    let range  =  document.createRange();
    range.selectNodeContents(table);
    let select =  window.getSelection();
    select.removeAllRanges();
    select.addRange(range);
    document.execCommand('copy');

  }



}
