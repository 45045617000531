import { Component, OnInit } from '@angular/core';
import {Alarm} from './alarm.model';
import {AlarmService} from './alarm.service';
import {SearchService} from '../search/search.service';
import * as jwt_decode from 'jwt-decode';
import {LoginService} from '../login/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.css']
})
export class AlarmComponent implements OnInit {

  alarm: Alarm;
  sinConexion: boolean;

  constructor(private alarmService: AlarmService,
              private searchService: SearchService,
              private loginService: LoginService,
              private router: Router) {
    this.updateToken()
    this.sinConexion = false;
    this.alarm = new Alarm('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '')
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  ngOnInit() {
    this.alarmService.getLastAlarm()
      .subscribe(
        (alarm: Alarm) => {
          this.alarmService.getAlarm(alarm.Id)
              .subscribe(
                (a: Alarm) => {
                  if (a.SubjectCode ) {
                    this.alarm = a;
                  }

                },
                (err) => { this.sinConexion = true; },
                // The 3rd callback handles the "complete" event.
                () => {}
              );
        },
        (err) => {this.sinConexion = false; },

      );
  }

}
