import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {Sujeto} from '../subject/sujeto.model';
import {ActivatedRoute, Router} from '@angular/router';
import {IdentifyService} from './identify.service';
import {SubjectService} from '../subject/subject.service';
import * as EXIF from 'exif-js/exif';
import * as jwt_decode from 'jwt-decode';
import {LoginService} from '../login/login.service';
import {SearchService} from '../search/search.service';
import {ErrorService} from '../error/error.service';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-identify',
  templateUrl: './identify.component.html',
  styleUrls: ['./identify.component.css'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotatedleft', style({ transform: 'rotate(-90deg)' })),
      state('rotateddown', style({ transform: 'rotate(-180deg)' })),
      state('rotatedright', style({ transform: 'rotate(-270deg)' })),
      transition('rotated => default', animate('1500ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})
export class IdentifyComponent implements OnInit {
  file;
  nuevaFoto = '';
  overlay = false;
  SubjectPhoto = '';
  subjects: Sujeto[];
  loading = true;
  noresults = false;
  state = 'default';


  constructor(private router: Router, private identifyService: IdentifyService, private subjectService: SubjectService,
              private loginService: LoginService, private searchService: SearchService,
              private errorService: ErrorService) {
    this.updateToken();
    this.loading = false;
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  ngOnInit() {
  }

  isValid() {
    if (this.nuevaFoto) {
      return true;
    }
    return false;
  }

  imageUpload(e) {
    this.state = 'default';
    const reader = new FileReader();
    const file = e.target.files[0];

    const ext = file.name.split('.').pop();

    console.log("EXT");
    console.log(ext);

    if (ext === 'JPG' || ext === 'PNG' || ext === 'jpg' || ext === 'png' || ext === 'JPEG' || ext === 'jpeg' ) {
      reader.onloadend = () => {
        this.nuevaFoto = <string>reader.result;
        this.file = file;
        EXIF.getData(this.file,  function () {
          const orientation = EXIF.getTag(this, 'Orientation');
          console.log("EXIF");
          console.log(orientation);
        });
      }
      reader.readAsDataURL(file);
    } else {
      // HEIC/HEIF
      const er = {
        title: 'Format not Allowed',
        error: {message: 'Picture must be JPG on PNG. IPhone users, change camera settings from Settings - Camera - Formats - Most Compatible.'}
      }
      this.errorService.handleError(er);

    }// end if
  }


  rotate() {
    if (this.state === 'default') {
      this.state = 'rotatedleft';
    } else if (this.state === 'rotatedleft') {
      this.state = 'rotateddown';
    } else if (this.state === 'rotateddown') {
      this.state = 'rotatedright';
    } else if (this.state === 'rotatedright') {
      this.state = 'default';
    }
  }

  isLoading() {
    return this.loading;
  }

  onCancel(form: NgForm) {
    this.loading = false;
    this.subjects = [];
    this.SubjectPhoto = '';
    this.overlay = true;
    form.resetForm();
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  onSubmit(form: NgForm) {
    this.loading = true;
    if (this.file) {
      const orientation = this.file.exifdata.Orientation;

      console.log("EXIF - OnSubmit");
      console.log(orientation);

      const img = new Image();
      img.src = this.nuevaFoto;

      const MAX_WIDTH = 500;
      const MAX_HEIGHT = 500;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      const canvas: HTMLCanvasElement = <HTMLCanvasElement> document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;

      const cwidth  = canvas.width;  const styleWidth  = canvas.style.width;
      const cheight =  canvas.height; const styleHeight = canvas.style.height;

      console.log("Antes del IF");
      console.log(orientation);

      /*if (orientation !== 0  ) {
        if (orientation > 4 ) {
          console.log("PRUEBA");
          canvas.width  = height; canvas.style.width  = styleHeight;
          canvas.height = width;  canvas.style.height = styleWidth;
        }
        switch (orientation) {
          //console.log("no voy a rotar nada");
          case 2: break;//ctx.translate(cwidth, 0);     ctx.scale(-1, 1); break;
          case 3: break;//ctx.translate(cwidth, cheight); ctx.rotate(Math.PI); break;
          case 4: break;//ctx.translate(0, cheight);     ctx.scale(1, -1); break;
          case 5: break;//ctx.rotate(0.5 * Math.PI);   ctx.scale(1, -1); break;
          case 6: break;//ctx.rotate(0.5 * Math.PI);   ctx.translate(0, -cheight); break;
          case 7: break;//ctx.rotate(0.5 * Math.PI);   ctx.translate(cwidth, -cheight); ctx.scale(-1, 1); break;
          case 8: break;//ctx.rotate(-0.5 * Math.PI);  ctx.translate(-cwidth, 0); break;
        }
      }*/
      ctx.drawImage(img, 0, 0, width, height);
      const dataurl = canvas.toDataURL();
      this.SubjectPhoto = dataurl;
    }
    this.subjects = [];
    this.identifyService.identifyPhoto(this.SubjectPhoto)
      .subscribe(
        (subjects: Sujeto[]) => {
          //console.log(subjects);
          if (Array.isArray(subjects)) {
            this.subjects = subjects;
          }
          let transformedsubjects: Sujeto[] = [];
          let place = 0;

          //console.log(this.subjects);

          if (this.subjects.length !== 0) {
            for (const subject of subjects) {
              place = place + 1;
              this.subjectService.getSubject(subject['usercode'])
                .subscribe(
                  (s: Sujeto) => {
                    const p = Math.round(subject['porc'] * 100);
                    s.Comments = p.toString(); //  + ' %';
                    transformedsubjects.push(s);
                  },
                  (err) => {console.error(err);
                  },
                  // The 3rd callback handles the "complete" event.
                  () => {
                    if (subjects.length === place) {
                      this.loading = false;
                      this.noresults = false;

                      transformedsubjects = transformedsubjects.sort(function(a, b) {
                        //console.log(Number(a.Comments));
                        return  Number(b.Comments) - Number(a.Comments);
                      });
                      this.subjects = transformedsubjects;
                    }
                  }

                );
            }
          } else {
            this.loading = false;
            this.noresults = true;
          }
          /*transformedsubjects = transformedsubjects.sort(function(a, b) {
            console.log(Number(a.Comments));
            return Number(a.Comments) - Number(b.Comments);
          });*/
          // this.subjects = transformedsubjects;
        },
        (err) => {
          console.error(err);
        },
        // The 3rd callback handles the "complete" event.
        () => {
          if (this.subjects.length === 0) {
            this.loading = false;
            this.noresults = true;
          } else {
            this.noresults = false;

          }
        }
      );
  }
}
