import { Component, OnInit } from '@angular/core';
import {Config} from './config.model';
import {ConfigurationService} from './configuration.service';
import {NgForm} from '@angular/forms';
import {Group} from '../administration/group.model';
import {Router} from '@angular/router';
import {User} from '../administration/user.model';
import * as jwt_decode from 'jwt-decode';
import {LoginService} from '../login/login.service';
import {SearchService} from '../search/search.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
  mongo: Config;
  biomaster: Config;
  herta: Config;
  postgres: Config;
  overlay = false;

  constructor(private configurationService: ConfigurationService,
              private router: Router,
              private loginService: LoginService,
              private searchService: SearchService) {
    this.updateToken();
    this.mongo = new Config('mongo', '');
    this.biomaster = new Config('biomaster', '');
    this.herta = new Config('herta', '');
    this.postgres = new Config('postgres', '');
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  ngOnInit() {
    this.configurationService.getConfig('mongo')
      .subscribe(
        (c: Config) => {
          this.mongo = c;
        }
      );
    this.configurationService.getConfig('biomaster')
      .subscribe((c: Config) => {
        this.biomaster = c; } );
    this.configurationService.getConfig('herta')
      .subscribe((c: Config) => {
        this.herta = c; } );
    this.configurationService.getConfig('postgres')
      .subscribe((c: Config) => {
        this.postgres = c; } );
  }
  isValid(form: NgForm) {
    if (this.mongo.value !== '' && this.biomaster.value !== '' && this.herta.value !== '' && this.postgres.value !== '') {
      return true;
    }
    return false;
  }
  onCancel() {
    this.router.navigateByUrl('/dashboard');
  }

  onSubmit(form: NgForm) {
    this.mongo.value = form.value.mongo;
    this.biomaster.value = form.value.biomaster;
    this.herta.value = form.value.herta;
    this.postgres.value = form.value.postgres;
    this.configurationService.saveConfig(this.mongo).subscribe();
    this.configurationService.saveConfig(this.biomaster).subscribe();
    this.configurationService.saveConfig(this.herta).subscribe();
    this.configurationService.saveConfig(this.postgres).subscribe();
    this.router.navigateByUrl('/dashboard');
  }
}
