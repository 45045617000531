import { EventEmitter } from '@angular/core';

import { Error } from './error.model';

export class ErrorService {
  errorOccurred = new EventEmitter<Error>();

  constructor() {
  }


  handleError(error: any) {
    // console.log('Handling the error');
    // console.log(error);
    const errorData = new Error(error.title, error.error.message);
    this.errorOccurred.emit(errorData);
    // console.log(errorData);

  }
}
