import {Routes} from '@angular/router';
import {ConfigurationComponent} from './configuration.component';
import {GroupComponent} from '../administration/group/group.component';
import {GrouplistComponent} from '../administration/grouplist/grouplist.component';
import {VerifyconfComponent} from '../administration/verifyconf/verifyconf.component';

export const CONFIGURATION_ROUTES: Routes = [
  { path: '', redirectTo: 'configuration', pathMatch: 'full' },
  { path: 'configuration', component: ConfigurationComponent },
  {path: 'grouplist', component: GrouplistComponent },
  {path: 'group', component: GroupComponent },
  {path: 'group/:name', component: GroupComponent },
  {path: 'verifyconf', component: VerifyconfComponent },
];
