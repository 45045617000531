import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ErrorService} from '../error/error.service';
import {Sujeto} from '../subject/sujeto.model';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

@Injectable()
export class IdentifyService {
  private subjects: Sujeto[] = [];
  private url = environment.backend;

  constructor(private http: Http, private errorService: ErrorService) {
  }

  identifyPhoto(photo: string)  {
    let lugar = photo.indexOf('base64,');
    lugar = lugar + 7;
    const nuevafoto = photo.substr(lugar);
    let newSubject: Sujeto;
    newSubject = new Sujeto('', 'false', '', '', '', '', '', '',  '', '', '', '');
    newSubject.SubjectPhoto = nuevafoto;
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';
    const body = JSON.stringify(newSubject);
    const headers = new Headers({'Content-Type': 'application/json'});

    return this.http.post(this.url + 'identify' + token, body, {headers: headers})
      .map((response: Response) => {
        const obj = response.json().obj;
        return obj;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

}
