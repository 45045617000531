import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {HeaderComponent} from './header/header.component';

import {Routing} from './app.routing';
import { LoginComponent } from './login/login.component';
import {LoginService} from './login/login.service';
import {HttpModule} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AdminmenuComponent } from './administration/adminmenu/adminmenu.component';
import { UserComponent } from './administration/user/user.component';
import { UserlistComponent } from './administration/userlist/userlist.component';
import {AdministrationService} from './administration/administration.service';
import { GrouplistComponent } from './administration/grouplist/grouplist.component';
import { GroupComponent } from './administration/group/group.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ErrorComponent } from './error/error.component';
import {ConfirmationDialogService} from './confirmation-dialog/confirmation-dialog.service';
import {ErrorService} from './error/error.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from './search/search.component';
import {SearchService} from './search/search.service';
import { SubjectComponent } from './subject/subject.component';
import {SubjectService} from './subject/subject.service';
import {ToastrModule} from 'ngx-toastr';
import {NgxPaginationModule} from 'ngx-pagination';
import { IdentifyComponent } from './identify/identify.component';
import {IdentifyService} from './identify/identify.service';
import { AlarmComponent } from './alarm/alarm.component';
import {AlarmService} from './alarm/alarm.service';
import { ConfigurationComponent } from './configuration/configuration.component';
import {ConfigurationService} from './configuration/configuration.service';
import { ConfigmenuComponent } from './configuration/configmenu/configmenu/configmenu.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { ReportsComponent } from './reports/reports.component';
import { ReportsService } from './services/reports/reports.service';
import { ChartsModule } from 'ng2-charts';
//import {html2pdf} from 'html2pdf.js';



import { FilterComponent } from './filter/filter.component';
import { 
  MatButtonToggleModule,
  MatRadioModule, 
  MatDatepickerModule, 
  MatCheckboxModule, 
  MatFormFieldModule, 
  MatNativeDateModule, 
  MatInputModule,
  MatButtonModule,
  MatSelectModule} from '@angular/material';
import { CameraService } from './services/camera/camera.service';
import { BlockUIModule } from 'ng-block-ui';
import { VerifyComponent } from './verify/verify.component';
import {VerifyService} from './verify/verify.service';
import { VerifyconfComponent } from './administration/verifyconf/verifyconf.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    AdminmenuComponent,
    UserComponent,
    UserlistComponent,
    GrouplistComponent,
    GroupComponent,
    ConfirmationDialogComponent,
    ErrorComponent,
    SearchComponent,
    SubjectComponent,
    IdentifyComponent,
    AlarmComponent,
    ConfigurationComponent,
    ConfigmenuComponent,
    ReportsComponent,
    FilterComponent,
    VerifyComponent,
    VerifyconfComponent
  ],
  imports: [
    MatSelectModule,
    MatButtonModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule, 
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    BrowserModule,
    Routing,
    ChartsModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule.forRoot(),
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    NgxPaginationModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [
    LoginService,
    CameraService,
    AdministrationService,
    ConfirmationDialogService,
    ErrorService,
    SearchService,
    SubjectService,
    IdentifyService,
    AlarmService,
    ConfigurationService,
    ReportsService,
    VerifyService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
