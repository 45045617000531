import {Component, OnInit} from '@angular/core';
import {User} from './administration/user.model';
import {LoginService} from './login/login.service';
import {Router} from '@angular/router';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit {
  private user: User;

  environmentValue = environment;

  constructor(private loginService: LoginService, private router: Router) {
    this.user = new User('', '', false, false);
  }

  ngOnInit() {
    if (this.loginService.isLoggedIn() === false) {
      // console.log('no token in memory');
      this.router.navigate(['/login']);
    } else {
      this.loginService.isBackLogged()
        .subscribe(
          (r: Object) => {
            // console.log(r);
            if (r === false) {
              this.router.navigate(['/login']);
            }
          },
          (err) => {
            // console.log(err);
            this.router.navigate(['/login']);
          }
        );
    }
    // console.log('Loged');
  }


}
