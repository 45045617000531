import { Component, OnInit } from '@angular/core';
import {Group} from '../group.model';
import {AdministrationService} from '../administration.service';
import {ConfirmationDialogService} from '../../confirmation-dialog/confirmation-dialog.service';
import {LoginService} from '../../login/login.service';
import {SearchService} from '../../search/search.service';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-grouplist',
  templateUrl: './grouplist.component.html',
  styleUrls: ['./grouplist.component.css']
})
export class GrouplistComponent implements OnInit {

  groups: Group[];
  sinConexion: boolean;

  constructor(private administrationService: AdministrationService,
              private confirmationDialogService: ConfirmationDialogService,
              private loginService: LoginService,
              private searchService: SearchService) {
    this.sinConexion = false;
    this.updateToken();
  }


  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  ngOnInit() {
    this.administrationService.getGroups()
      .subscribe(
        (users: Group[]) => {
          this.groups = users;
        },
        error => {
          console.error(error);
          this.sinConexion = true;
        },
      );
  }
  onDelete(g: Group) {
     this.confirmationDialogService.confirm('Delete', 'Delete group ' + g.name + '?')
     .then((confirmed) => {
    if (confirmed) {
      this.administrationService.deleteGroup(g)
        .subscribe(
          result => {
            if (result.message === 'Deleted Group') {
              this.groups.splice(this.groups.indexOf(g), 1);
            }

          },
          (err) => console.error(err)
        );

    }

  } )
   .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
}}
