import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {LoginService} from '../login/login.service';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  usuario  = '';
  constructor(private router: Router, private loginService: LoginService) {

  }

  ngOnInit() {
  }

  onLogout() {
    this.usuario = '';
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

  isLoggedIn(){
    this.usuario = this.loginService.setUser();
    return this.loginService.isLoggedIn();
  }

  isLogInPage(){
    if (this.router.url === '/login'){
      return true;
    }
    return false;
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canMarketing() {
    return this.loginService.canMarketing();
  }

  canEnroll() {
    return this.loginService.canEnroll();
  }

  canConfig() {
    return this.loginService.canConfig();
  }

}
