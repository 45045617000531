import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {AdministrationService} from '../administration.service';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../../login/login.service';
import * as jwt_decode from 'jwt-decode';
import {SearchService} from '../../search/search.service';
import {ConfigurationService} from '../../configuration/configuration.service';
import {Config} from '../../configuration/config.model';

@Component({
  selector: 'app-verifyconf',
  templateUrl: './verifyconf.component.html',
  styleUrls: ['./verifyconf.component.css']
})
export class VerifyconfComponent implements OnInit {
  verify: Config;
  myForm: FormGroup;
  name: string;

  constructor(private router: Router,
              private administratorService: AdministrationService,
              private route: ActivatedRoute,
              private loginService: LoginService,
              private searchService: SearchService, private configurationService: ConfigurationService) {
    this.updateToken();
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  ngOnInit() {
    this.configurationService.getConfig('verify')
      .subscribe(
        (c: Config) => {
          this.verify = c;
          this.name = c.value;
        }
      );

    this.myForm = new FormGroup({
      name: new FormControl(null, Validators.required)
    });
  }
  onSubmit() {
    this.verify.value = this.myForm.value.name;
    this.configurationService.saveConfig(this.verify).subscribe();
    // console.log('Submit Group');
    this.name = this.myForm.value.name;
    console.log(this.name);
    this.router.navigateByUrl('/verify');
  }
}
