import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Observable, Subject} from 'rxjs';
import { environment } from '../../environments/environment';
import {ErrorService} from '../error/error.service';
import {Alarm} from './alarm.model';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

@Injectable()
export class AlarmService {
  private url = environment.backend;
  alarm: Alarm;

  constructor(private http: Http, private errorService: ErrorService) {
  }

  getLastAlarm() {

    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.get(this.url + 'alarms' + token)
      .map((response: Response) => {
        //console.log(response);
        const codigo = response.json().obj;
        this.alarm = new Alarm(
          '',
          '',
          '',
          '',
          '',
          codigo,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        )
        return this.alarm;

      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  getLastDetection() {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.get(this.url + 'alarms/detection' + token)
      .map((response: Response) => {
        const codigo = response.json().obj;
        this.alarm = new Alarm(
          '',
          '',
          '',
          '',
          '',
          codigo,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        )

        return this.alarm;

      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  getAlarm(codigo: string) {

    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';

    return this.http.get(this.url + 'alarms?codigo=' + codigo + token )
      .map((response: Response) => {
        const obj = response.json().obj;

        const newDate = new Date(obj.TimeStamp);
        newDate.setHours(newDate.getHours() - 3);

        const alarm = new Alarm(
          obj.CameraId,
          obj.FacePosition,
          obj.FaceSize,
          obj.FrameImage,
          obj.FrameIndex,
          obj.Id,
          obj.Quality,
          obj.Score,
          obj.SubjectCode,
          obj.SubjectFaceImage,
          obj.SubjectName,
          newDate.toDateString() +  ' ' + newDate.toTimeString(),
          obj.SubjectGroup,
          obj.SubjectId,
          obj.SubjectLastName,
          obj.SubjectModelImage
        );
        return alarm;

      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }
}
