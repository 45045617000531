import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Group } from '../group.model';
import {AdministrationService} from '../administration.service';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../../login/login.service';
import * as jwt_decode from 'jwt-decode';
import {SearchService} from '../../search/search.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {

  myForm: FormGroup;
  name: string;
  group: Group;
  constructor(private router: Router,
              private administratorService: AdministrationService,
              private route: ActivatedRoute,
              private loginService: LoginService,
              private searchService: SearchService) {
    this.group = new Group('');
    this.updateToken();
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.name = params['name'];
      this.myForm = new FormGroup({
        name: new FormControl(null, Validators.required)
      });
      this.group = new Group('');
    });
  }
  onSubmit() {
    // console.log('Submit Group');
    const group = new Group(
      this.myForm.value.name
    );
    this.administratorService.addGroup(group)
        .subscribe(
          data => {
             // console.log(data);
          },
          error => console.error(error),
          // The 3rd callback handles the "complete" event.
          () => {this.router.navigateByUrl('/configuration/grouplist'); }
        );
  }
}
