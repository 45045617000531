import {Role} from './role.model';
import {Group} from './group.model';

export class User {
  constructor(public email: string,
              public password: string,
              public notify: boolean,
              public isuser: boolean,
              public firstName?: string,
              public lastName?: string,
              public roles?: Role[],
              public groups?: Group[],
              public id?: string,
              public phone?: string) {}
}
