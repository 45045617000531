import { Routes, RouterModule } from '@angular/router';
import {BIOWEB_ROUTES} from './dashboard/dashboard.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import {LoginComponent} from './login/login.component';
import {LOGIN_ROUTES} from './login/login.routes';
import {AdminmenuComponent} from './administration/adminmenu/adminmenu.component';
import {ADMINISTRATION_ROUTES} from './administration/administration.routes';
import {SearchComponent} from './search/search.component';
import {SEARCH_ROUTES} from './search/search.routes';
import {SubjectComponent} from './subject/subject.component';
import {IdentifyComponent} from './identify/identify.component';
import {AlarmComponent} from './alarm/alarm.component';
import {VerifyComponent} from './verify/verify.component';
import {CONFIGURATION_ROUTES} from './configuration/configuration.routes';
import {ConfigmenuComponent} from './configuration/configmenu/configmenu/configmenu.component';
import { ReportsComponent } from './reports/reports.component';

const APP_ROUTES: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent, children: BIOWEB_ROUTES},
  { path: 'login', component: LoginComponent, children: LOGIN_ROUTES },
  { path: 'administration', component: AdminmenuComponent, children: ADMINISTRATION_ROUTES },
  { path: 'search', component: SearchComponent, children: SEARCH_ROUTES },
  {path: 'subject/:codigo', component: SubjectComponent },
  {path: 'subject', component: SubjectComponent },
  {path: 'identify', component: IdentifyComponent},
  {path: 'verify', component: VerifyComponent},
  {path: 'alarm', component: AlarmComponent},
  {path: 'configuration', component: ConfigmenuComponent,  children: CONFIGURATION_ROUTES},
  { path: 'reports', component: ReportsComponent }
];

export const Routing = RouterModule.forRoot(APP_ROUTES);
