import { Component, OnInit } from '@angular/core';
import {Sujeto} from './sujeto.model';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {AdministrationService} from '../administration/administration.service';
import {Group} from '../administration/group.model';
import * as EXIF from 'exif-js/exif';
import {LoginService} from '../login/login.service';
import {ToastrService} from 'ngx-toastr';
import {SubjectService} from './subject.service';
import {SearchService} from '../search/search.service';
import * as jwt_decode from 'jwt-decode';
import {ErrorService} from '../error/error.service';
import {Error} from '../error/error.model';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotatedleft', style({ transform: 'rotate(-90deg)' })),
      state('rotateddown', style({ transform: 'rotate(-180deg)' })),
      state('rotatedright', style({ transform: 'rotate(-270deg)' })),
      transition('rotated => default', animate('1500ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})
export class SubjectComponent implements OnInit {
  codigo: string;
  subject: Sujeto;
  active: boolean;
  nuevo: boolean;
  files;
  groups: Group[];
  grupoOriginal = '';
  overlay = false;
  nuevoComment = '';
  nuevaFotos: string[];
  images = [];
  nuevaFoto = '';
  puedeEditar = true;
  sinConexion: boolean;
  cambioFotos = false;
  loading = false;
  state = 'default';

  constructor(private loginService: LoginService, private route: ActivatedRoute,
              private administrationService: AdministrationService,
              private router: Router, private toastrService: ToastrService,
              private subjectService: SubjectService,
              private searchService: SearchService,
              private errorService: ErrorService) {
    this.updateToken();
    this.subject = new Sujeto('', 'false', '', '', '', '', '2050-01-01', '', '', '', '', '');
    this.nuevaFotos = new Array();
    this.files = new Array();
    this.sinConexion = false;
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }
  canEnroll() {
    return this.loginService.canEnroll();
  }

  isLoading() {
    return this.loading;
  }

  ngOnInit() {
    this.searchService.getGroups().subscribe(
      (groups: Group[]) => {
        this.groups = groups;
      },
      (err) => {
        console.error(err);
        this.sinConexion = true;
        });
    this.route.params.subscribe(params => {
      this.codigo = params['codigo'];

      if (this.codigo) {
        this.subjectService.getSubject(this.codigo)
          .subscribe(
            (s: Sujeto) => {
              this.subject = s;
              console.log(this.subject);

              if (this.subject.Active === 'true') {
                this.active = true;
              } else {
                this.active = false;
              }
              this.nuevo = false;
              this.nuevaFoto = this.subject.SubjectPhoto;
              this.grupoOriginal = this.subject.Group;
              this.subjectService.getImages(this.codigo)
                .subscribe(
                  (f: string[]) => {
                    this.nuevaFotos = f;
                    this.subject.SubjectPhotos =  this.nuevaFotos;
                  },
                  (err) => {
                  },
                  () => {
                      this.loading = false;
                  }
                );
            },
            (err) => {
            },
            () => {
              this.loading = false;
            }
          );
        /*this.subjectService.getImages(this.codigo)
          .subscribe(
          (f: Object) => {
            console.log(f);
          }
        );*/
      } else {
        this.nuevo = true;
        this.active = false;
        this.subject = new Sujeto('', 'false', '', '', '', '', '2050-01-01', '', '', '', '', '');
        this.subject.Created = 'nuevo';
      }
    });
  }



  isValid(form: NgForm){
    if (form.value.UserCode && this.subject.Group && this.nuevaFoto && form.value.Name && form.value.LastName) {
      return true;

    }

    return false;

  }

  hasGroup(g: Group) {
    if (this.subject) {
      const s = this.subject;
      const value = this.subject.Group;
      if (value === g.name) {
        return true;
      }

    }



    return false;
  }
  grupoPermitido(g: Group) {
    if (this.grupoOriginal !== '') // SI no tiene derecho en el grupo al que pertenece no se lo dejo cambiar
    {
      let gr: Group;
      gr = new Group(this.grupoOriginal);
      if (this.loginService.grupoPermitido(gr) === false) {
        return false;
      }
    }
    return this.loginService.grupoPermitido(g);

  }
  onClickedGroup(group, event) {
    if (event.target.checked) {
      this.subject.Group = group.name;
    }

  }

  mostrarAutorizar(grupo: Group) {
    if (this.loginService.canActivate() ) {
        return true;
    }
    return false;
  }

  canEdit() {
    if (this.loginService.canActivate()) {
      return true;
    }
    return false;
  }

  imageUpload(e) {

    this.state = 'default';
    this.cambioFotos = true;

    if ( this.subject.Created === 'nuevo') {
      this.nuevaFotos = [];
    }
    this.nuevaFoto = '';

    let i = 0;
    for (const file of e.target.files) {


      const ext = file.name.split('.').pop();

      if (ext === 'JPG' || ext === 'PNG' || ext === 'jpg' || ext === 'png' || ext === 'JPEG' || ext === 'jpeg' ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.nuevaFotos.push(<string>reader.result);
          const img = new Image();
          img.src = <string>reader.result;
          this.images.push(img);
          if (! this.nuevaFoto || this.subject.Created !== 'nuevo') {
            this.nuevaFoto = this.nuevaFotos[this.nuevaFotos.length - 1];
          }
          this.files.push(file);
          console.log(file);
          EXIF.getData(file,  function () {
            const orientation = EXIF.getTag(this, 'Orientation');
          });
        }
        reader.readAsDataURL(file);
        i = i + 1;
      } else {
        // HEIC/HEIF
       const er = {
         title: 'Format not Allowed',
         error: {message: 'Picture must be JPG on PNG. IPhone users, change camera settings from Settings - Camera - Formats - Most Compatible.'}
       }
        this.errorService.handleError(er);

      }// end if
    }
  }
  changeActive(e) {
    this.active = e.target.checked;
    if (this.active === true) {
      this.subject.Active = 'true';
    } else {
      this.subject.Active = 'false';
    }
  }
  onCancel() {
    this.router.navigateByUrl('/search');
  }
  setComment(ev) {
    try {
      this.nuevoComment = ev.target.value;
    } catch (e) {
      //console.log('could not set textarea-value');
    }
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }


  getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }


  onSubmit(form: NgForm) {
    let g: Group;
    g = new Group(this.subject.Group);

    if (!this.grupoPermitido(g)) {
      this.puedeEditar = false;
    } else {
      this.overlay = true;
      let viejas: string[];
      viejas = new Array();
      viejas = this.subject.SubjectPhotos;
      if (this.files && this.cambioFotos === true) {
        this.subject.SubjectPhotos = new Array();
        let i = 0;
        for (const file of this.files) {
          const orientation = file.exifdata.Orientation;


          const os = this.getOS();
          console.log("OS");
          console.log(os);
          
          const img = this.images[i];
          const MAX_WIDTH = 500;
          const MAX_HEIGHT = 500;
          if (img.width === 0) {
            // console.log('esperar');
          }
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          const canvas: HTMLCanvasElement = <HTMLCanvasElement> document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = width;
          canvas.height = height;
          const cwidth = canvas.width;
          const styleWidth = canvas.style.width;
          const cheight = canvas.height;
          const styleHeight = canvas.style.height;

          console.log("ORIENTATION");
          console.log(orientation);

          
          //alert(this.getOS());

          if (orientation !== 0) {
            if (orientation > 4) {
              canvas.width = height;
              canvas.style.width = styleHeight;
              canvas.height = width;
              canvas.style.height = styleWidth;
            }
            switch (orientation) {
              case 2:
                //ctx.translate(cwidth, 0);
                //ctx.scale(-1, 1);
                //alert("DOS");
                break;
              case 3:
                //ctx.translate(cwidth, cheight);
                //ctx.rotate(Math.PI);
                //alert("TRES");
                break;
              case 4:
                //ctx.translate(0, cheight);
                //ctx.scale(1, -1);
                //alert("CUATRO");
                break;
              case 5:
                //ctx.rotate(0.5 * Math.PI);
                //ctx.scale(1, -1);
                //alert("CINCO");
                break;
              case 6:

                console.log("OS ADENTRO");
                console.log(os);

                //if (os !== "iOS"){
                    //ctx.rotate(0.5 * Math.PI);
                    //ctx.translate(0, -cheight);
                    //alert("NO ES iOS VOY A ROTAR");
                //}
               
                break;
              case 7:
                //ctx.rotate(0.5 * Math.PI);
                //ctx.translate(cwidth, -cheight);
                //ctx.scale(-1, 1);
                //alert("SIETE");
               
                break;
              case 8:
                //ctx.rotate(-0.5 * Math.PI);
                //ctx.translate(-cwidth, 0);
               
                //alert("OCHO");
                break;
            }
          }
          ctx.drawImage(img, 0, 0, width, height);
          const dataurl = canvas.toDataURL();
          this.subject.SubjectPhotos.push(dataurl);
          i = i + 1;
        }
        //console.log(viejas); //tiene todas pero no estan rotadas las nuevas.
        //console.log(this.subject.SubjectPhotos); //tiene solo las nuevas
        if (viejas != null){
          for (const k of this.subject.SubjectPhotos) { //saco las nuevas de las viejas
            viejas.pop();
          }
          for (const s of viejas) { //Le agrego las viejas a las nuevas
            this.subject.SubjectPhotos.push(s);
          }
        }


        //console.log(viejas);
        //console.log(this.subject.SubjectPhotos);
        /*for (const s of viejas) {
          if ( this.subject.SubjectPhotos.includes(s) === false) {
            this.subject.SubjectPhotos.push(s);
          }
        }*/
        //this.subject.SubjectPhoto = this.subject.SubjectPhotos[0];

      }/*else {
        this.subject.SubjectPhoto = '';
        this.subject.SubjectPhotos = [];
      }*/
      this.subject.LastName = form.value.LastName;
      this.subject.Name = form.value.Name;
      this.subject.UserCode = form.value.UserCode;
      if (this.subject.Created === 'nuevo') {
        this.subject.Comments = 'Bioweb - Created by ' + localStorage.getItem('usuario');
      } else {
        this.subject.Comments = 'Bioweb - Updated by ' + localStorage.getItem('usuario');
      }
      if (this.nuevoComment !== '') {
        this.subject.Comments =  this.subject.Comments + ': ' + this.nuevoComment;
      }

      this.subjectService.saveSubject(this.subject)
        .subscribe(
          result => {
            //console.log(result);
          },
          (err) => {
            this.overlay = false;
            console.error(err);
            if (err.error.messaje === 'Existe') {
              this.subject.UserCode = '';
            }
          }
          ,
          // The 3rd callback handles the "complete" event.
          () => {
            this.overlay = false;
            this.router.navigateByUrl('/search?code=' + this.subject.UserCode);
          }
        );
    }
  }

  rotate() {
    if (this.state === 'default') {
        this.state = 'rotatedleft';
    } else if (this.state === 'rotatedleft') {
      this.state = 'rotateddown';
    } else if (this.state === 'rotateddown') {
      this.state = 'rotatedright';
    } else if (this.state === 'rotatedright') {
      this.state = 'default';
    }

   // this.state = (this.state === 'default' ? 'rotated' : 'default');
  }
}
