import { Component, OnInit } from '@angular/core';
import {LoginService} from '../login/login.service';
import {SearchService} from '../search/search.service';
import * as jwt_decode from 'jwt-decode';
import {Alarm} from '../alarm/alarm.model';
import {AlarmService} from '../alarm/alarm.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  alarm: Alarm;
  detection: Alarm;
  sinConexion: boolean;
  todos: any;

  constructor(private alarmService: AlarmService,
              private searchService: SearchService,
              private loginService: LoginService,
              private router: Router) {
    this.updateToken()
    this.sinConexion = false;
    this.alarm = new Alarm('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '')
    this.detection = new Alarm('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '')
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canMarketing() {
    return this.loginService.canMarketing();
  }

  redirectToReports(){
    if (this.loginService.canMarketing()){
      this.router.navigateByUrl('/reports');
    }
    
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  ngOnInit() {

    if (!this.canBioweb()){
      this.redirectToReports();
    }
    else{

    this.alarmService.getLastAlarm()
      .subscribe(
        (alarm: Alarm) => {
          this.alarmService.getAlarm(alarm.Id)
            .subscribe(
              (a: Alarm) => {
                if (a.SubjectCode ) {
                  this.alarm = a;
                }

              },
              (err) => { this.sinConexion = true; },
              // The 3rd callback handles the "complete" event.
              () => {}
            );
        },
        (err) => {this.sinConexion = false; },
      );

    this.alarmService.getLastDetection()
      .subscribe(
        (detection: Alarm) => {
          this.alarmService.getAlarm(detection.Id)
            .subscribe(
              (d: Alarm) => {
                //if (d.SubjectCode ) {
                  this.detection = d;
                //}

              },
              (err) => { this.sinConexion = false; },
              // The 3rd callback handles the "complete" event.
              () => {}
            );
        },
        (err) => {this.sinConexion = false; },
      );
    this.searchService.getCount().subscribe(
      (res: Response) => {
        this.todos = res;
      });
  }
}

}
