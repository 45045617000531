export class Alarm {
  CameraId: string;
  FacePosition: string;
  FaceSize: string;
  FrameImage: string;
  FrameIndex: string;
  Id: string;
  Quality: string;
  Score: string;
  SubjectCode: string;
  SubjectFaceImage: string;
  SubjectName: string;
  TimeStamp: string;
  SubjectGroup: string;
  SubjectId: string;
  SubjectLastName: string;
  SubjectModelImage: string;


  constructor(
    CameraId: string,
    FacePosition: string,
    FaceSize: string,
    FrameImage: string,
    FrameIndex: string,
    Id: string,
    Quality: string,
    Score: string,
    SubjectCode: string,
    SubjectFaceImage: string,
    SubjectName: string,
    TimeStamp: string,
    SubjectGroup: string,
    SubjectId: string,
    SubjectLastName: string,
    SubjectModelImage: string
  ){
    this.CameraId = CameraId;
    this.FacePosition = FacePosition;
    this.FaceSize = FaceSize;
    this.FrameImage = FrameImage;
    this.FrameIndex = FrameIndex;
    this.Id = Id;
    this.Quality = Quality;
    this.Score = Score;
    this.SubjectCode = SubjectCode;
    this.SubjectFaceImage = SubjectFaceImage;
    this.SubjectName = SubjectName;
    this.TimeStamp = TimeStamp;
    this.SubjectGroup = SubjectGroup;
    this.SubjectId = SubjectId;
    this.SubjectLastName = SubjectLastName;
    this.SubjectModelImage = SubjectModelImage;

  }
}
