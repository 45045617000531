import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { ErrorService } from 'src/app/error/error.service';
import { IDemographicsStatisticsRequest } from './demographic-statistics-request.model';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class ReportsService {

  private url = environment.backend;

  constructor(private http: Http, 
              private errorService: ErrorService) {}

  demographicStatistics(request: IDemographicsStatisticsRequest) {
    const body = JSON.stringify(request);
    const header = new Headers({'Content-Type': 'application/json'});

    return this.http.post(this.url + 'demographics', body, {headers: header} )
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  } 

  demographicStatisticsGET() {
    const header = new Headers({'Content-Type': 'application/json'});

    return this.http.get(environment.backend + 'demographics', {headers: header} )
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  } 
}
