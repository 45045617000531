import { Component, OnInit } from '@angular/core';
import { Error } from './error.model';
import { ErrorService } from './error.service';
import {LoginService} from '../login/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  error: Error;
  display = 'none';

  constructor(private errorService: ErrorService, private router: Router, private loginService: LoginService) {}

  onErrorHandled() {
    this.display = 'none';
  }

  mostrarLogin() {
    if (this.error) {
      if (this.error.title === 'Not Authenticated' || this.error.title === 'You are not logged in or your session has expired.') {
        return true;
      }
    }
    return false;
  }
  onLogout() {
    this.display = 'none';
    this.loginService.logout();
    this.router.navigate(['/login']);
  }



  ngOnInit() {
    this.errorService.errorOccurred
      .subscribe(
        (error: Error) => {
          this.error = error;
          this.display = 'block';
        }
      );
  }
}
