import { Component, OnInit, isDevMode } from '@angular/core';
import { IDemographicsStatisticsRequest } from '../services/reports/demographic-statistics-request.model';
import { CameraService } from '../services/camera/camera.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  public inactiveCameras = [];

  camerasFormControl = new FormControl();

  public demographicStatistics: IDemographicsStatisticsRequest = {
    AgeFilter: {EndAge:100, StartAge: 1},
    DateFilter: {DaysOfWeek: [{DayOfWeek: "Monday"},{DayOfWeek: "Tuesday"},{DayOfWeek: "Sunday"},{DayOfWeek: "Thursday"},{DayOfWeek: "Wednesday"},{DayOfWeek: "Saturday"},{DayOfWeek: "Friday"}], EndDate: "2019-11-06T17:42:05.174064-03:00", StartDate: "2016-11-06T17:42:05.173329-03:00"},
    EthnicityFilter: {AddAsianPeople: true, AddBlackPeople: true, AddWhitePeople:true},
    ExcludeSources: {string: []},
    GenderFilter: {AddFemales:true, AddMales:true},
    GlassesFilter:{AddPeopleWithGlasses:true, AddPeopleWithoutGlasses:true}
  };

  constructor(
    private cameraService: CameraService,
  ) { }

  ngOnInit() {
    this.getInactiveCameras();
  }

  getInactiveCameras() {
    this.cameraService.inactiveCameras().subscribe(
      data => {
        this.inactiveCameras = data.obj.string;
      },
      error => {
        console.error(error);
      }
    )
  }

  changeGender(event){
    console.log(this.demographicStatistics);
    switch(event.value){
      case 'male':
        this.demographicStatistics.GenderFilter.AddFemales = false;
        this.demographicStatistics.GenderFilter.AddMales = true;
        break;
      case 'female':
        this.demographicStatistics.GenderFilter.AddFemales = true;
        this.demographicStatistics.GenderFilter.AddMales = false;
        break;
      case 'all':
        this.demographicStatistics.GenderFilter.AddFemales = true;
        this.demographicStatistics.GenderFilter.AddMales = true;
        break;
    }
  }

  changeGlasses(event) {
    switch(event.value){
      case 'yes':
        this.demographicStatistics.GlassesFilter.AddPeopleWithGlasses = true;
        this.demographicStatistics.GlassesFilter.AddPeopleWithoutGlasses = false;
        break;
      case 'no':
        this.demographicStatistics.GlassesFilter.AddPeopleWithGlasses = false;
        this.demographicStatistics.GlassesFilter.AddPeopleWithoutGlasses = true;
        break;
      case 'all':
        this.demographicStatistics.GlassesFilter.AddPeopleWithGlasses = true;
        this.demographicStatistics.GlassesFilter.AddPeopleWithoutGlasses = true;
        break;
    }
  }

  setDayOfWeek(event) {
    const day = this.demographicStatistics.DateFilter.DaysOfWeek.find(day => day.DayOfWeek == event.source.value);
    if(day){
      const index = this.demographicStatistics.DateFilter.DaysOfWeek.indexOf(day);
      this.demographicStatistics.DateFilter.DaysOfWeek.splice(index, 1);
    }
    else {
      this.demographicStatistics.DateFilter.DaysOfWeek.push({DayOfWeek: event.source.value});
    }
    console.log(this.demographicStatistics.DateFilter.DaysOfWeek);
  }

  addExcludedCamera(event) {
    this.demographicStatistics.ExcludeSources.string = event.value;
    // var source = this.demographicStatistics.ExcludeSources.string.find(source => source == event.source.value);
    // if(source){
    //   const index = this.demographicStatistics.ExcludeSources.string.indexOf(source);
    //   this.demographicStatistics.ExcludeSources.string.splice(index, 1);
    // }
    // else {
    //   this.demographicStatistics.ExcludeSources.string.push(event.source.value);
    // }
  }

}
