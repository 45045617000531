import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { ErrorService } from 'src/app/error/error.service';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class CameraService {

  private url = environment.backend;

  constructor(private http: Http, 
              private errorService: ErrorService) {}

  inactiveCameras() {
    return this.http.get(this.url + 'camera/inactiveCameras')
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }
}
