import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {User} from '../user.model';
import { Role } from '../role.model';
import { Group } from '../group.model';
import {AdministrationService} from '../administration.service';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../../login/login.service';
import {isUndefined} from 'util';
import {SearchService} from '../../search/search.service';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent  implements OnInit  {
  myForm: FormGroup;

  roles: Role[];
  groups: Group[];
  selectedroles: any;
  selectedgroups: any;
  codigo: string;
  user: User;
  notificaciones: boolean;

  constructor(private router: Router,
              private administratorService: AdministrationService,
              private route: ActivatedRoute, private loginService: LoginService,
              private searchService: SearchService) {
    this.updateToken();
    this.user = new User('', '', false,  true);
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }


  ngOnInit() {

    this.administratorService.getRoles()
      .subscribe(
        (roles: Role[]) => {
          this.roles = roles;
        }
      );

    this.searchService.getGroups()
      .subscribe(
        (groups: Group[]) => {
          this.groups = groups;
        }
      );

    this.selectedroles = [];
    this.selectedgroups = [];

    this.route.params.subscribe(params => {
      this.codigo = params['codigo']; // (+) converts string 'id' to a number


      this.myForm = new FormGroup({
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, Validators.required),
        email: new FormControl(null, [
          Validators.required,
          Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
        ]),
        //phone: new FormControl(null, [
        //  Validators.pattern("(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)-[0-9]{8,14}")
        //]),
        phone: new FormControl(this.user.phone),
        password: new FormControl(null, Validators.required)
      });



      if (this.codigo) {
        // console.log(this.user);
        this.loginService.loadUserById(this.codigo)
          .subscribe(
            (u: User) => {
              this.user = u;
              for (const r of this.user.roles){
                this.loginService.loadRole(r.name).subscribe(
                  (r1: any) => {
                    // console.log(r);
                    this.selectedroles.push(r1);
                  });
              }
              for (const g of this.user.groups){
                this.searchService.loadGroup(g.name).subscribe(
                  (g1: any) => {
                    // console.log(g);
                    this.selectedgroups.push(g1);

                  });
              }
              this.myForm = new FormGroup({
                firstName: new FormControl(this.user.firstName, Validators.required),
                lastName: new FormControl(this.user.lastName, Validators.required),
                email: new FormControl(this.user.email, [
                  Validators.required,
                  Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
                ]),
                //phone: new FormControl(this.user.phone, [
                 // Validators.pattern("(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)-[0-9]{8,14}")
                //]),
                phone: new FormControl(this.user.phone),
                password: new FormControl(null)
              });
              this.notificaciones = this.user.notify;

              if (isUndefined(this.user.notify)) {
                this.user.notify = false;
                this.notificaciones = false;
              }
              // console.log(this.user);
            }
          );

      } else {
        this.notificaciones = false;
        this.user = new User('', '', false, true);
      }
    });



  }
  onClickedRole(role, event) {
    if (event.target.checked) {
      this.selectedroles.push(role);
    } else {
      this.selectedroles.splice(this.selectedroles.indexOf(role), 1);
    }
  }
  onClickedGroup(group, event) {
    if (event.target.checked) {
      this.selectedgroups.push(group);
    } else {
      // this.selectedgroups.splice(group);
      this.selectedgroups.splice(this.selectedgroups.indexOf(group), 1);
    }
  }

  hasGroup(g: Group) {
    for (const gr of this.selectedgroups){
      if (gr === g.name) {
        return true;
      }
    }
  }

  hasRole(r: Role) {
    for (const rr of this.selectedroles){
      if (rr === r.name) {
        return true;
      }
    }
    return false;
  }

  changeNotificacion(e) {

    this.notificaciones = e.target.checked;
    if (this.notificaciones == true){
      this.user.notify = true;
    }else {
      this.user.notify = false;
    }
  }
  canAdmin() {
    return this.loginService.canAdmin();
  }

  onSubmit() {

    //console.log("GUARDAR");
    // console.log(this.myForm.value);
    // console.log(this.selectedroles);
    // console.log(this.selectedgroups);

    const user = new User(
      this.myForm.value.email,
      this.myForm.value.password,
      this.notificaciones, true,
      this.myForm.value.firstName,
      this.myForm.value.lastName
    );

    user.roles = this.selectedroles;
    user.groups = this.selectedgroups;
    user.phone = this.myForm.value.phone;
    user.id = this.user.id;
    user.notify = this.notificaciones;
    user.isuser = true;

    //console.log('Usuario a Guardar');
    //console.log(user);




    if (this.codigo) {
      this.administratorService.editUser(user)
        .subscribe(
          data => {
            // console.log(data);
          },
          error => console.error(error),
          // The 3rd callback handles the "complete" event.
          () => {this.router.navigateByUrl('/administration/userlist'); }
        );
    } else {
      this.administratorService.addUser(user)
        .subscribe(
          data => {
            // console.log(data);
          },
          error => console.error(error),
          // The 3rd callback handles the "complete" event.
          () => {this.router.navigateByUrl('/administration/userlist'); }
        );
    }
  }
}
