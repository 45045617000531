import { Component, OnInit } from '@angular/core';
import {User} from '../user.model';
import {AdministrationService} from '../administration.service';
import {ConfirmationDialogService} from '../../confirmation-dialog/confirmation-dialog.service';
import {LoginService} from '../../login/login.service';
import {SearchService} from '../../search/search.service';
import * as jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})
export class UserlistComponent implements OnInit {

  users: User[];
  sinConexion: boolean;
  error: string;

  constructor(private administrationService: AdministrationService,
              private confirmationDialogService: ConfirmationDialogService,
              private loginService: LoginService,
              private searchService: SearchService) {
    this.updateToken()
    this.sinConexion = false;
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {console.error(error); },
      );
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }


  ngOnInit() {
    this.administrationService.getUsers()
      .subscribe(
        (users: User[]) => {
          this.users = users;
        },
        error => {
          console.error(error);
          this.sinConexion = true;
          this.error = error.title;
        },
      );
  }
  onDelete(u: User) {
     this.confirmationDialogService.confirm('Delete', 'Delete user ' + u.lastName + '?')
       .then((confirmed) => {
    if (confirmed) {
          this.administrationService.deleteUser(u)
            .subscribe(
              result => {
                if (result.message === 'Deleted User') {
                  this.users.splice(this.users.indexOf(u), 1);
                }

              },
              (err) => console.error(err)
            );

        }

      } )
       .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }}
