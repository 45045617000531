import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ErrorService} from '../error/error.service';
import {Sujeto} from '../subject/sujeto.model';
import {LoginService} from '../login/login.service';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {Group} from '../administration/group.model';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class SearchService {
  private subjects: Sujeto[] = [];
  private url = environment.backend;

  constructor(private http: Http, private errorService: ErrorService, private loginService: LoginService) {
  }

  getCount() {
          const token = localStorage.getItem('token')
            ? '?token=' + localStorage.getItem('token')
            : '';
          return this.http.get(this.url + 'search' + token)
            .map((response: Response) => {
              //console.log(response);
              const resp = response.json().obj;
              return resp[0].total;

            })
            .catch((error: Response) => {
              this.errorService.handleError(error.json());
              return Observable.throw(error.json());
            });
  }

  loadGroup(key: string ) {
  const token = localStorage.getItem('token')
    ? '&token=' + localStorage.getItem('token')
    : '';
  const headers = new Headers({'Content-Type': 'application/json'});
  return this.http.get(this.url + 'search/groups?groupid=' + key + token, {headers: headers} )
    .map((resp: Response) => {
      const obj = resp.json().obj.name;
      //console.log(obj);
      return obj;
    });
}

  getGroups() {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';
    return this.http.get(this.url + 'search/groups' + token)
      .map((response: Response) => {
        const groups = response.json().obj;
        const transformedGroups: Group[] = [];
        for (const group of groups) {
          transformedGroups.push(new Group(
            group.name)
          );
        }
        return transformedGroups;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        //onsole.log(error);
        return Observable.throw(error.json());
      });
  }

  search(subjectData: Object) {

    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    const body = JSON.stringify(subjectData);
    const headers = new Headers({'Content-Type': 'application/json'});

    return this.http.post(this.url + 'search' + token, body, {headers: headers})
      .map((response: Response) => {
        const codigos = response.json().obj.data;
        const cant = response.json().obj.count ;
        const transformedsubjects: Sujeto[] = [];

        //console.log(response);

        for (const codigo of codigos) {
          transformedsubjects.push(new Sujeto(
            codigo.pd_code, cant.toString(),
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
          ));
        }
        this.subjects = transformedsubjects;
        return transformedsubjects;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }
}
