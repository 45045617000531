export class Sujeto {
  UserCode: string;
  Active: string;
  BioLastUpdate: string;
  BioUpdates: string;
  Comments: string;
  Created: string;
  ExpiryDate: string;
  Group: string;
  LastName: string;
  LastUpdate: string;
  Name: string;
  SubjectPhoto: string;
  SubjectPhotos?: string[];

  constructor( UserCode: string,
               Active: string,
               BioLastUpdate: string,
               BioUpdates: string,
               Comments: string,
               Created: string,
               ExpiryDate: string,
               Group: string,
               LastName: string,
               LastUpdate: string,
               Name: string,
               SubjectPhoto: string) {

    this.UserCode = UserCode;
    this.Active = Active;
    this.BioLastUpdate = BioLastUpdate;
    this.BioUpdates = BioUpdates;
    this.Comments = Comments;
    this.Created = Created;
    this.ExpiryDate = ExpiryDate;
    this.Group = Group;
    this.LastName = LastName;
    this.LastUpdate = LastUpdate;
    this.Name = Name;
    this.SubjectPhoto = SubjectPhoto;
  }
}
