import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ErrorService} from '../error/error.service';
import {Config} from './config.model';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

@Injectable()
export class ConfigurationService {
  private url = environment.backend;

  constructor(private http: Http, private errorService: ErrorService) {
  }
  getConfig(name: string) {
    //console.log('get congfig');
    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';

    //console.log(this.url + 'config?nombre=' + name + token);
    return this.http.get(this.url + 'config?nombre=' + name + token)
      .map((response: Response) => {
        const obj = response.json().obj;
        const subject = new Config(obj.name, obj.value);
        //console.log(subject);
        return subject;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        //console.log(error);
        return Observable.throw(error.json());
      });
  }
  saveConfig(config: Config) {

    const body = JSON.stringify(config);
    const headers = new Headers({'Content-Type': 'application/json'});

    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';
    return this.http.post(this.url + 'config' + token, body, {headers: headers})
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }
}
