import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {Sujeto} from '../subject/sujeto.model';
import {ActivatedRoute, Router} from '@angular/router';
import {VerifyService} from './verify.service';
import {SubjectService} from '../subject/subject.service';
import * as EXIF from 'exif-js/exif';
import * as jwt_decode from 'jwt-decode';
import {LoginService} from '../login/login.service';
import {SearchService} from '../search/search.service';
import {ErrorService} from '../error/error.service';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotatedleft', style({ transform: 'rotate(-90deg)' })),
      state('rotateddown', style({ transform: 'rotate(-180deg)' })),
      state('rotatedright', style({ transform: 'rotate(-270deg)' })),
      transition('rotated => default', animate('1500ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})
export class VerifyComponent implements OnInit {
  file;
  nuevaFoto = '';
  code = '';
  result = '';
  overlay = false;
  SubjectPhoto = '';
  subjects: Sujeto[];
  loading = true;
  noresults = false;
  state = 'default';
  estilo = '';


  constructor(private router: Router, private verifyService: VerifyService, private subjectService: SubjectService,
              private loginService: LoginService, private searchService: SearchService,
              private errorService: ErrorService) {
    this.updateToken();
    this.loading = false;
  }

  updateToken() {
    this.loginService.updateToken()
      .subscribe(
        data => {
          // console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.userId);
          const decoded = <any>jwt_decode(data.token);
          localStorage.setItem('usuario', decoded.user.firstName + ' ' + decoded.user.lastName);

          for (const rol of decoded.user.roles) {

            this.loginService.loadRole(rol).subscribe(
              (rol1: any) => {
                localStorage.setItem('rol' + rol1, 'true');
              });
          }
          for (const g of decoded.user.groups) {

            this.searchService.loadGroup(g).subscribe(
              (g1: any) => {
                localStorage.setItem('group' + g1, 'true');
              });
          }
        },
        error => {
          console.error(error);
        },
      );
  }

  ngOnInit() {
  }

  isValid() {
    if (this.nuevaFoto && this.code) {
      return true;
    }
    return false;
  }

  onKeyCode(e) {

    this.code = e.target.value;
  }

  imageUpload(e) {
    this.state = 'default';
    const reader = new FileReader();
    const file = e.target.files[0];

    const ext = file.name.split('.').pop();

    if (ext === 'JPG' || ext === 'PNG' || ext === 'jpg' || ext === 'png' || ext === 'JPEG' || ext === 'jpeg') {
      reader.onloadend = () => {
        this.nuevaFoto = <string>reader.result;
        this.file = file;
        EXIF.getData(this.file, function () {
          const orientation = EXIF.getTag(this, 'Orientation');
        });
      }
      reader.readAsDataURL(file);
    } else {
      // HEIC/HEIF
      const er = {
        title: 'Format not Allowed',
        error: {message: 'Picture must be JPG on PNG. IPhone users, change camera settings from Settings - Camera - Formats - Most Compatible.'}
      }
      this.errorService.handleError(er);

    }// end if
  }


  rotate() {
    if (this.state === 'default') {
      this.state = 'rotatedleft';
    } else if (this.state === 'rotatedleft') {
      this.state = 'rotateddown';
    } else if (this.state === 'rotateddown') {
      this.state = 'rotatedright';
    } else if (this.state === 'rotatedright') {
      this.state = 'default';
    }
  }

  isLoading() {
    return this.loading;
  }

  onCancel(form: NgForm) {
    this.loading = false;
    this.subjects = [];
    this.SubjectPhoto = '';
    this.overlay = true;
    form.resetForm();
    this.result = '';
    this.code = '';
  }


  estiloResultado() {
    return this.estilo;
  }

  canBioweb() {
    return this.loginService.canBioweb();
  }

  canAdmin() {
    return this.loginService.canAdmin();
  }

  onSubmit(form: NgForm) {
    this.result = '';
    this.estilo = 'nada';
    console.log('CODIGO');
    console.log(this.code);
    this.loading = true;
    if (this.file) {
      const orientation = this.file.exifdata.Orientation
      const img = new Image();
      img.src = this.nuevaFoto;

      const MAX_WIDTH = 500;
      const MAX_HEIGHT = 500;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      const canvas: HTMLCanvasElement = <HTMLCanvasElement> document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;

      const cwidth = canvas.width;
      const styleWidth = canvas.style.width;
      const cheight = canvas.height;
      const styleHeight = canvas.style.height;

      if (orientation !== 0) {
        if (orientation > 4) {
          canvas.width = height;
          canvas.style.width = styleHeight;
          canvas.height = width;
          canvas.style.height = styleWidth;
        }
        switch (orientation) {
          case 2:
            //ctx.translate(cwidth, 0);
            //ctx.scale(-1, 1);
            break;
          case 3:
            //ctx.translate(cwidth, cheight);
            //ctx.rotate(Math.PI);
            break;
          case 4:
            //ctx.translate(0, cheight);
            //ctx.scale(1, -1);
            break;
          case 5:
            //ctx.rotate(0.5 * Math.PI);
            //ctx.scale(1, -1);
            break;
          case 6:
            //ctx.rotate(0.5 * Math.PI);
            //ctx.translate(0, -cheight);
            break;
          case 7:
            //ctx.rotate(0.5 * Math.PI);
            //ctx.translate(cwidth, -cheight);
            //ctx.scale(-1, 1);
            break;
          case 8:
            //ctx.rotate(-0.5 * Math.PI);
            //ctx.translate(-cwidth, 0);
            break;
        }
      }
      ctx.drawImage(img, 0, 0, width, height);
      const dataurl = canvas.toDataURL();
      this.SubjectPhoto = dataurl;
    }
    this.subjects = [];
    this.verifyService.verifyPhoto(this.SubjectPhoto, this.code)
      .subscribe(
        result => {
          console.log(result);
          if (result === 'NO RESULTS') {
            this.noresults = true;
            this.result = 'NO RESULTS';
            this.estilo = 'noresults';
          } else {
            let r = result;
            r = r * 100;
            if (r >= 50) {
              this.estilo = 'exito';
              this.result = 'MATCH!';
            } else {
              this.estilo = 'fracaso';
              this.result = 'NO MATCH';
            }
            console.log(r.toFixed(0));
            //this.result = r.toFixed(0) + ' %';
          }
        },
        (err) => {
          this.overlay = false;
          console.error(err);
        }
        ,
        // The 3rd callback handles the "complete" event.
        () => {
          this.loading = false;
          this.noresults = false;
        }
      );
  }}

