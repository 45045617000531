import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ErrorService} from '../error/error.service';
import {Sujeto} from 'src/app/subject/sujeto.model';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {isArray} from 'rxjs/util/isArray';

@Injectable()
export class SubjectService {
  private subjects: Sujeto[] = [];
  private url = environment.backend;

  constructor(private http: Http, private errorService: ErrorService) {
  }

  getSubjects() {
    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';

    return this.http.get(this.url + 'subject' + token)
      .map((response: Response) => {
        const codigos = response.json().obj;
        const transformedsubjects: Sujeto[] = [];

        if (isArray(codigos)) {
          for (const codigo of codigos) {
            transformedsubjects.push(new Sujeto(
              codigo,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              ''
            ));
          }
        } else {
          transformedsubjects.push(new Sujeto(
            codigos,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
          ));
        }
        this.subjects = transformedsubjects;
        return transformedsubjects;

      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  getSubject(codigo: string) {
    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';
    return this.http.get(this.url + 'subject?codigo=' + codigo + token)
      .map((response: Response) => {
        const obj = response.json().obj;
        //console.log(obj);
        const img = 'data:image/PNG;base64,'  + obj.SubjectPhoto;
        let g = '';
        if (obj.Group == null) {
          g = '';
        } else {
          g = obj.Group;
        }
        const subject = new Sujeto(
          obj.UserCode,
          obj.Active,
          obj.BioLastUpdate,
          obj.BioUpdates,
          obj.Comments,
          obj.Created,
          obj.ExpiryDate,
          g,
          obj.LastName,
          obj.LastUpdate,
          obj.Name, img
        );
        return subject;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }


  getSnapshot(codigo: string) {
    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';
    return this.http.get(this.url + 'subject/images?codigo=' + codigo + token)
      .map((response: Response) => {
        const imagenes = response.json().obj;
        const img = 'data:image/PNG;base64,'  + imagenes.Snapshot;
        return img;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  getImages(codigo: string) {
    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';
    return this.http.get(this.url + 'subject/images?codigo=' + codigo + token)
      .map((response: Response) => {
        const imagenes = response.json().obj;
        //console.log(imagenes.Snapshot);
        //console.log(imagenes.Images.base64Binary);

        const transformedImages: string[] = [];


        if (isArray(imagenes.Images.base64Binary)) {
          //console.log('es array');
          for (const image of imagenes.Images.base64Binary) {
            const img = 'data:image/PNG;base64,'  + image;
            transformedImages.push(img);
          }
        } else {
          //console.log('no es array');
          //console.log(imagenes.Images.base64Binary);

          const img = 'data:image/PNG;base64,'  + imagenes.Images.base64Binary;
          transformedImages.push(img);
        }
        return transformedImages;
      })
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }


  deleteSubject(subject: Sujeto) {

    const token = localStorage.getItem('token')
      ? '&token=' + localStorage.getItem('token')
      : '';
    // console.log(subject.UserCode);
    return this.http.delete(this.url + 'subject/' + subject.UserCode + '?group=' + subject.Group +  token)
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });

  }

  saveSubject(subject: Sujeto) {
    let newSubject: Sujeto;
    newSubject = subject;
    newSubject.SubjectPhoto = newSubject.SubjectPhotos[0];

    const body = JSON.stringify(newSubject);
    const headers = new Headers({'Content-Type': 'application/json'});

    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';
    return this.http.post(this.url + 'subject' + token, body, {headers: headers})
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }

  saveSubjectDetails(subject: Sujeto) {
    let newSubject: Sujeto;
    newSubject = subject;
    const body = JSON.stringify(newSubject);
    const headers = new Headers({'Content-Type': 'application/json'});

    const token = localStorage.getItem('token')
      ? '?token=' + localStorage.getItem('token')
      : '';
    return this.http.post(this.url + 'subject/details' + token, body, {headers: headers})
      .map((response: Response) => response.json())
      .catch((error: Response) => {
        this.errorService.handleError(error.json());
        return Observable.throw(error.json());
      });
  }
}
